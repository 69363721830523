* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $link;
	font-size: inherit;
	line-height: inherit;
	transition: .5s all ease-in-out 0s;
	&:hover {
		color: $link_hover;
	}
}

span, strong, div {
	font-size: inherit;
	line-height: inherit;
}

h1 {
	@include font-size(24);
	@media #{$large}{
		@include font-size(28);
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: 600;
	color: $h1;
	strong {
		color: $h1;
		font-weight: inherit;
	}
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
	span.first-word {
		color: $h1;
	}
}

h2 {
	@include font-size(20);
	@media #{$large}{
		@include font-size(24);
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-weight: bold;
	color: $h2;
	strong {
		color: $link;
		font-weight: inherit;
	}
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
	> span {
		text-transform: uppercase;
		font-weight: normal;
	}
}

h3 {
	color: $h3;
	@include font-size(18);
	margin-top: 0;
	font-weight: 600;
	margin-bottom: 24px;
	text-transform: uppercase;
	strong {
		color: $link;
		font-weight: inherit;
	}
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

h4 {
	font-weight: bold;
	margin-bottom: 12px;
	color: $h4;
	margin-top: 0;
	strong {
		color: $link;
		font-weight: inherit;
	}
	* {
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
	}
}

p {
	color: $base;
	margin-bottom: 24px;
	@include font-size(14, 1.5);
}

ul, ol {
	margin-left: 1rem;
	li {
		&::marker {
			color: $link;
		}
	}
}

h6.card-header {
	min-height: 45px;
}

h6.card-header, .card-header h6 {
	font-weight: 600;
	@include font-size(14);
	margin-bottom: 0;
}

.card-header h6 {
	position: relative;
	top: 2px;
}

.item-page, .custom, .blog-featured, .blog-archive, .blog-category {
	ul, ol {
		margin-bottom: 24px;
		li {
			margin-bottom: 6px;
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	clear: both;
	background: $blue1;
	border: 0;
	opacity: 1;
	height: 2px;
	margin-bottom: 12px;
}

.item-image {
	display: block;
	margin: 0 auto 6px;
	text-align: center;
	.img_caption {
		@include font-size(14);
		max-width: 100%;
	}
	&.pull-right {
		@media #{$medium}{
			float: right;
			margin-left: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: right;
			padding-top: 12px;
		}
	}
	&.pull-left {
		@media #{$medium}{
			float: left;
			margin-right: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: left;
			padding-top: 12px;
		}
	}
}

/*Artikelinfos*/
dl.article-info {
	@include font-size(12);
	margin-bottom: 12px;
	span {
		@include font-size(12);
	}
	time {
		@include font-size(12);
	}
	dd.createdby {
		@include font-size(12);
		span {
			@include font-size(12);
		}
	}
}

/*Pagination*/
.pagination, nav.pagination__wrapper {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	margin-top: 24px;
	p.counter {
		display: none;
		margin-bottom: 0;
		@media #{$large}{
			float: right;
			display: block;
		}
	}
	ul {
		margin-left: 0;
		width: 100%;
		li {
			list-style: none;
			display: inline;
			margin-right: 10px;
			a {
				padding: 5px 10px;
				color: $link;
				background: $light;
				border: 1px solid $dark;
				text-align: center;
				&:hover {
					color: $link-hover;
				}
			}
			span {
				padding: 5px 10px;
			}
			&.pagination-end, &.pagination-start {
				display: none;
			}
			&.pagination-next {
				@media #{$large-max}{
					float: right;
					margin-right: 0;
				}
			}
			@media #{$large-max}{
				display: none;
				&.pagination-prev, &.pagination-next {
					display: inline-block;
				}
			}
		}
	}
}

ul.pagenav {
	width: 100%;
	float: left;
	clear: both;
	margin-left: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			background: $main;
			color: $light;
			padding: 5px;
			@include font-size(12);
			display: block;
			margin-bottom: 12px;
			&:hover {
				background: $link_hover;
			}
		}
	}
	li.pagenav-prev {
		float: left;
		a:before {
			content: '<';
			padding-right: 5px;
		}
	}
	li.pagenav-next {
		float: left;
		clear: both;
		@media #{$medium}{
			float: right;
			clear: none;
		}
		a:after {
			content: '>';
			padding-left: 5px;
		}
	}
}

.btn:focus, button:focus {
	outline: 0 !important;
	box-shadow: none !important;
}

/* Fieldset reset */
fieldset {
	border: 0;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
	.btn-group {
		float: left;
		label.element-invisible {
			display: none;
		}
		#filter-search {
			min-width: 210px;
			padding: 4px;
			background: $dark;
			color: $light;
			border-radius: 3px;
			@include font-size(14);
		}
		&.pull-right {
			float: right;
			select {
				border: 1px solid $border;
			}
		}
	}
}

.btn {
	@include font-size(14);
}

div.item-image {
	max-width: 100%;
	@media #{$medium}{
		max-width: 50%;
	}
	@media #{$xlarge}{
		max-width: 33.333%;
	}
}

select {
	border-width: 1px;
	background-color: $bginput;
	min-height: 32px;
	padding: 11px 6px 10px;
	&.form-select {
		padding: 11px 6px 10px;
		background-color: $bginput;
		&:focus {
			box-shadow: none !important;
			outline: 0;
		}
	}
	&:focus {
		box-shadow: none !important;
	}
	&.small {
		min-height: 24px;
		font-size: 14px;
		padding: 5px 32px 4px 6px;
	}
}
input.form-control.small{
	font-size: 14px;
	padding: 5px 6px;
}

figure.item-image, div.item-image, span.item-image {
	&.left, &.pull-left {
		float: left;
		margin-right: 48px !important;
		margin-bottom: 24px;
		position: relative;
		padding-left: 6px;
		padding-bottom: 12px;
		img {
			box-shadow: 0px 3px 12px -5px #00000099;
			position: relative;
			margin-top: -12px !important;
			display: inline-block !important;
			margin-right: -24px !important;
			transition: all .3s cubic-bezier(.2, .5, .3, 1);
		}
	}
	&.right, &.pull-right {
		&:before {
			background: $link;
			left: 6px;
			top: 2px;
		}
		float: right;
		margin-left: 48px !important;
		margin-top: 0;
		position: relative;
		padding-right: 6px;
		padding-top: 0;
		margin-bottom: 48px;
		img {
			box-shadow: 0px 3px 12px -5px #00000099;
			position: relative;
			margin-bottom: 0 !important;
			bottom: -21px;
			display: inline-block !important;
			margin-left: -24px !important;
			transition: all .3s cubic-bezier(.2, .5, .3, 1);
		}
	}
	&:before {
		position: absolute;
		box-shadow: 0px 3px 6px #00000099;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: $link;
	}
	&:hover img {
		transform: scale(1.05);
		box-shadow: 0px 3px 12px -1px #00000099;;
	}
}

.loading_screen {
	width: 100%;
	position: relative;
	text-align: center;
	transform: scale(.4);
	.lds-roller {
		display: inline-block;
		position: relative;
		width: 32px;
		height: 32px;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 32px 32px;
	}
	.lds-roller div:after {
		content: " ";
		display: block;
		position: absolute;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: $light;
		box-shadow: 0 0 4px black;
		margin: -3px 0 0 -3px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 50px;
		left: 50px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 54px;
		left: 45px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 57px;
		left: 39px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 58px;
		left: 32px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 57px;
		left: 25px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 54px;
		left: 19px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 50px;
		left: 14px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 45px;
		left: 10px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.tooltip.fade {
	display: none !important;
}

input#remember {
	margin-bottom: 24px;
}

p.hint {
	@include font-size(14);
	color: $bgbox;
}

div[role="tooltip"] {
	display: none;
}

.btn-xs {
	@include font-size(12);
	padding: 4px;
}

/* Table */
table {
	max-width: 100%;
	width: 100%;
	color: $dark;
	margin-bottom: 24px;
	border-collapse: collapse;
	th {
		background-color: transparent;
		padding: 3px 5px;
		color: $base;
		text-align: left;
		border-bottom: 1px solid $border;
		@include font-size(12);
		transition: .5s all ease 0s;
		* {
			color: $base;
			margin-bottom: 0;
		}
		a {
			color: $link;
		}
		@media #{$medium}{
			@include font-size(16);
		}
	}
	tbody {
		tr {
			transition: .5s background-color ease 0s;
			td {
				padding: 3px 5px;
				vertical-align: top;
			}
		}
	}
	&.responsive {
		width: 100%;
		color: $dark;
		margin-bottom: 24px;
		table-layout: fixed;
		th {
			background-color: $dark;
			padding: 5px 10px;
			color: $light;
			text-align: left;
			@include font-size(12);
			transition: .5s all ease 0s;
			* {
				color: $light;
				margin-bottom: 0;
			}
			@media #{$medium}{
				@include font-size(16);
			}
		}
		tbody {
			tr {
				transition: .5s background-color ease 0s;
				td {
					padding: 5px 5px;
					vertical-align: top;
					@media #{$medium-max}{
						width: 50% !important;
						display: block;
						float: left;
						word-break: break-word;
					}
					@media #{$small-max}{
						width: 100% !important;
					}
					img {
						margin: 0 auto 24px;
						display: block;
						float: none !important;
					}
					@media #{$small}{
					}
					@media #{$medium}{
						padding: 5px 10px;
					}
					@media #{$large}{
						float: none;
						display: table-cell;
						box-sizing: border-box;
						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}
					}
					a {
						font-weight: bold;
					}
				}
			}
		}
	}
	&.zebra {
		border: 1px solid $border;
		tr {
			&:nth-child(odd) {
				background-color: $bg;
				&:hover {
					background-color: $bgbox;
				}
			}
			&:nth-child(even) {
				background-color: $light;
				&:hover {
					background-color: $bg;
				}
			}
			td {
				@media #{$medium}{
					border-right: 1px solid $border;
				}
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					padding-left: 5px;
				}
			}
		}
	}
	&.info {
		width: auto;
		@media #{$small-max}{
			width: 100% !important;
		}
		th, th * {
			@include font-size(13);
		}
		tr {
			td {
				@include font-size(13);
				* {
					@include font-size(13);
				}
				&:first-child, &:last-child {
					padding: 5px 5px;
					@media #{$medium}{
						padding: 5px 10px;
					}
				}
			}
		}
	}
	&.sticky {
		min-width: 724px;
		tr {
			td {
				@media #{$medium-max}{
					width: auto !important;
					display: table-cell;
					float: none;
					border-right: 1px solid $border;
					word-break: normal;
				}
				@media #{$small-max}{
					width: auto !important;
				}
			}
		}
	}
	&.stacked {
		width: 100% !important;
		tr {
			border-bottom: 1px solid $link_hover;
			margin-bottom: 1px;
			padding-bottom: 1px;
			&:first-child {
				td {
					font-weight: bold;
					* {
						margin-bottom: 0;
					}
				}
			}
			> td {
				padding: 5px !important;
				text-align: center;
				* {
					margin-bottom: 0;
					text-align: center;
				}
			}
			td:first-child {
				font-weight: bold;
				padding: 5px 10px;
				background: $bg;
			}
			td:nth-child(2) {
				background: lighten(#ddd, 10%)
			}
			td:nth-child(3) {
				background: lighten(#ddd, 5%)
			}
			td:nth-child(4) {
				background: #ddd;
			}
		}
	}
	&.category {
	}
	&.dfContactTable {
		tr th {
			width: 54px;
			padding: 5px 10px;
			text-align: center;
		}
	}
}

.sketch_grid {
	margin-bottom: 24px;
	&.tleft {
		float: left;
	}
}

ul.dropdown-menu {
	display: block;
	right: 0;
}

// Inputs
// Base Styling Inputs
.form-group {
	margin-bottom: 12px;
	label {
		@include font-size(14);
	}
}

.alert {
	&.alert-primary {
		border-color: $link;
	}
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea, input[type='search'], input.form-control, input[ type="color"], input[type="file"] {
	border: 1px solid $border;
	background: $bginput;
	min-height: 32px;
	padding: 12px 6px;
	border-radius: 6px;
	@include font-size(16, 1);
	&[readonly], &[readonly='readonly'] {
		background: #ddd;
		cursor: not-allowed;
	}
	&::placeholder {
		color: $placeholder;
	}
	&:focus {
		outline: 0 !important;
		box-shadow: none !important;
	}
	&.form-control {
		background: $bginput;
	}
}
input[type="color"]{
	min-height: 48px;
	width: 96px;
	&.small{
		min-height: 32px;
	}
}
input[type="file"]{
	min-height: 48px;
	line-height: 1.4;
	&.small{
		min-height: 32px;
	}
}
.ml-auto {
	margin-left: auto;
}

.sketchAvatar {
	width: 32px;
	height: 32px;
	border: 1px solid $border;
	border-radius: 100%;
	object-fit: cover;
	object-position: center;
}

.borderRow {
	border-bottom: 1px solid #e8e8e8;
}

div.multiselect {
	&.isMultiselect {
		&.small {
			.multiselect__tags {
				max-height: none;
				.multiselect__tags-wrap {
					padding: 0;
					.multiselect__tag {
						background: $blue;
						padding: 3px 19px 3px 10px;
						margin-right: 4px;
						margin-left: 4px;
						margin-bottom: 0;
					}
				}
			}
			.multiselect__option--selected:after {
				display: none;
			}
			.multiselect__placeholder {
				margin-bottom: 8px;
			}
			.multiselect__select {
				width: 16px;
				height: 16px;
			}
		}
	}
	.multiselect__tags {
		border: 1px solid $blue1;
		min-height: 47px;
		padding: 0;
		border-radius: $br;
	}
	.multiselect__tags-wrap {
		padding-left: 6px;
	}
	span.multiselect__placeholder {
		@include font-size(16, 1);
		margin-left: 12px;
		margin-top: 12px;
	}
	.multiselect__input {
		border: 0;
		background: transparent;
		padding-left: 12px;
	}
	.multiselect__content-wrapper {
		border: 1px solid $blue;
		border-top: 0;
		border-radius: 0 0 7px 7px;
	}
	.multiselect__option--highlight {
		background: $blue3;
		color: $base;
	}
	.multiselect__tag {
		margin-top: 5px;
	}
	.multiselect__single {
		line-height: 27px;
		margin-top: 8px;
		margin-left: 5px;
	}
	.multiselect__select {
		transition: none;
		background: $light;
		border-radius: 0 $br $br 0;
		width: 40px;
		height: 47px;
		line-height: 22px;
		z-index: 1;
		border: 1px solid $blue;
		right: 0;
		top: 0;
		&:before {
			top: 5px;
			transition: .3s all ease 0s;
			border-color: $blue transparent transparent transparent;
			display: inline-block;
		}
	}
	&.multiselect--active {
		.multiselect__select {
			transform: none;
			border-radius: 0px $br 0 $br;
			background: $blue;
			&:before {
				transform: rotate(180deg);
				top: 3px;
				border-color: $light transparent transparent transparent;
			}
		}
		.multiselect__tags {
			border-color: $blue;
			height: 47px;
			border-bottom-color: transparent;
		}
	}
	&.small {
		min-height: 32px;
		.multiselect__option--highlight::after {
			display: none;
		}
		.multiselect__option:after {
			display: none;
		}
		.multiselect__tags {
			min-height: 31px;
			max-height: 32px;
			input.multiselect__input {
				min-height: 32px;
				max-height: 32px;
				margin-bottom: 0;
				@include font-size(14, 1);
			}
		}
		span.multiselect__placeholder {
			@include font-size(14, 1);
			margin-left: 12px;
			margin-top: 6px;
		}
		.multiselect__single {
			line-height: 22px;
			font-size: 14px;
			margin-top: 5px;
			margin-bottom: 4px;
			padding-left: 12px;
			margin-left: 0;
			color: $blue;
		}
		.multiselect__select {
			width: 32px;
			height: 32px;
			line-height: 9px;
		}
	}
	ul.multiselect__content {
		li.multiselect__element {
			.multiselect__option {
				padding: 8px 12px;
				min-height: 0;
			}
		}
	}
}

.btn-close.isMultiselect {
	font-size: 7px;
}

button.clear-button {
	position: absolute;
	right: 0;
	z-index: 9999;
	border-radius: 0 7px 7px 0;
	background: #fff;
	padding: 4px 10px 5px 11px;
	i {
		font-size: 12px;
	}
}

//.btn.btn-outline-primary, .btn.btn-outline-danger, .btn.btn-outline-success {
//	&:hover, &.active {
//		color: $light;
//		i {
//			color: $light;
//		}
//	}
//}
.dp__main {
	.dp__clear_icon {
		height: 100% !important;
		box-sizing: border-box;
		width: 42px !important;
		border-radius: 0 $br $br 0;
	}
	svg.dp__icon.dp__input_icon.dp__input_icons {
		background: $light;
		color: $blue;
		right: 1px;
		border: 1px solid $blue;
		display: none;
		&:last-child {
			display: block;
		}
	}
	svg.dp__icon.dp__clear_icon.dp__input_icons {
		border-left: 1px solid $blue;
		color: $blue;
	}
	&.small {
		.dp__pointer.dp__input {
			height: 32px;
			line-height: 32px;
			border: 1px solid $blue1;
			border-radius: $br;
			color: $blue;
		}
		svg.dp__icon.dp__input_icon.dp__input_icons {
			height: 18px;
			width: 14px;
			background: $light;
			color: $blue;
			padding: 6px 8px 6px 8px;
			right: 1px;
			border: 1px solid $blue;
			display: none;
			&:last-child {
				display: block;
			}
		}
		svg.dp__icon.dp__clear_icon.dp__input_icons {
			border: 1px solid $blue;
			width: 18px;
			height: 18px;
			padding: 6px 6px 6px 7px;
			color: $blue;
			border-radius: 0 $br $br 0;
		}
		.dp__clear_icon {
			height: 100% !important;
			width: 32px !important;
		}
	}
	&.active {
		.dp__pointer.dp__input {
			border-color: $blue;
		}
	}
}

div.dp__today {
	border: 1px solid $dark;
}

.dp__main {
	max-width: 100%;
	margin-bottom: 12px;
	.vue-datepicker__input {
		width: 100%;
	}
	.dp__pointer::placeholder {
		font-size: 14px;
	}
	.dp__pointer.dp__input {
		padding: 6px 46px 6px 10px;
		border: 1px solid #e8e8e8;
		height: 47px;
	}
}

svg.dp__icon.dp__input_icon.dp__input_icons {
	left: auto;
	right: 0;
	background: #0088cb;
	color: #fff;
	height: 34px;
	border-radius: 0 5px 5px 0;
}

textarea, div.sketchcontact textarea {
	border: 1px solid $border;
	padding: 2px;
}

.sketch_calendar {
	.btn {
		padding: 1px 11px;
		border-radius: 0 5px 5px 0;
		span.icon-calendar {
			@include icon($icon-calendar);
			&:before {
				padding-right: 0;
			}
		}
	}
}

.ps__rail-y {
	border-radius: 7px;
}

// In Item-page
// Article Summary
.article-index {
	background: $bg;
	padding: 12px;
	margin-bottom: 24px;
	&.pull-right {
		float: right;
	}
	&.pull-left {
		float: left;
	}
}

.pagenavcounter {
	float: right;
	background: $dark;
	color: $light;
	border-radius: 8px;
	padding: 4px 8px;
	clear: right;
	margin-bottom: 24px;
}

.pager {
	ul {
		width: 100%;
		float: left;
		clear: both;
		margin-top: 24px;
		li {
			padding-left: 0;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.alert {
	background-color: rgba($light, .92);
	padding: 12px;
	overflow: hidden;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	border: 2px solid $border;
	.close {
		border: 1px solid $border;
		border-radius: 100%;
		width: 19px;
		line-height: 18px;
		height: 19px;
		display: block;
		text-align: center;
		color: $border;
		float: right;
		cursor: pointer;
	}
	p {
		margin-bottom: 6px;
	}
	&.alert-message, &.alert-info {
		border: 2px solid $main;
		.close {
			border: 1px solid $main;
			color: $main;
		}
	}
	&.alert-warning, &.alert-error {
		border: 2px solid $red;
		.close {
			border: 1px solid $red;
			color: $red;
		}
	}
	&.alert-success, &.alert-notice {
		border: 2px solid $green;
		.close {
			border: 1px solid $green;
			color: $green;
		}
	}
}

// Icons for Base Elements
span.fax {
	@include icon($icon-print);
}

.btn-edit {
	@include icon($icon-pencil);
}

.btn-delete {
	@include icon($icon-close);
}

.btn-save {
	@include icon($icon-check);
}

.btn-search {
	@include icon($icon-search);
}

.btn-more {
	@include icon($icon-arrow-right);
}

*[data-title] {
	cursor: pointer;
	position: relative;
	&:hover {
		&:after {
			content: attr(data-title);
			padding: 4px 8px;
			color: #333;
			position: absolute;
			left: 12px;
			top: 82%;
			@include font-size(14);
			max-width: 500px;
			text-transform: initial;
			z-index: 20;
			box-shadow: 0 0 4px #222;
			background: $bg;
		}
	}
}

// Frontend Joomla-native Popups
.popover {
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	background-color: $light;
	border: 1px solid $border;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	white-space: normal;
	position: absolute;
	&.fade {
		opacity: 0;
		-webkit-transition: opacity .15s linear;
		transition: opacity .15s linear;
		&.in {
			opacity: 1;
		}
	}
	&.top {
		margin-top: -10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-bottom-width: 0;
			border-top-color: $border;
			bottom: -11px;
			&:after {
				bottom: 1px;
				margin-left: -10px;
				border-bottom-width: 0;
				border-top-color: $light;
			}
		}
	}
	&.right {
		margin-left: 10px;
		> .arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-left-width: 0;
			border-right-color: $border;
			&:after {
				left: 1px;
				bottom: -10px;
				border-left-width: 0;
				border-right-color: $light;
			}
		}
	}
	&.bottom {
		margin-top: 10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-top-width: 0;
			border-bottom-color: $border;
			top: -11px;
			&:after {
				top: 1px;
				margin-left: -10px;
				border-top-width: 0;
				border-bottom-color: $light;
			}
		}
	}
	&.left {
		margin-left: -10px;
		> .arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: $border;
			&:after {
				right: 1px;
				border-right-width: 0;
				border-left-color: $light;
				bottom: -10px;
			}
		}
	}
	> .arrow {
		border-width: 11px;
		&:after {
			border-width: 10px;
			content: "";
		}
	}
	> .arrow, > .arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.popover-title {
		margin: 0;
		padding: 8px 14px;
		@include font-size(14);
		font-weight: 400;
		line-height: 18px;
		background-color: $main;
		color: $light;
		border-bottom: 1px solid $border;
		border-radius: 5px 5px 0 0;
	}
	.popover-content {
		padding: 9px 14px;
	}
}

.popover.bottom > .arrow:after, .popover.left > .arrow:after, .popover.right > .arrow:after, .popover.top > .arrow:after {
	content: " ";
}

span.copyright {
	display: block;
	text-align: right;
	color: $light;
	@include font-size(14);
}

.content_rating, form.rate_content {
	p.unseen.element-invisible {
		display: none;
	}
}

.content_rating {
	float: right;
	span.stars {
		color: gold;
		font-size: 27px;
		line-height: 24px;
		cursor: pointer;
	}
	span.rating_count {
		position: relative;
		top: -3px;
	}
}

// Material Radios/Checkbox
.radio {
	margin: 0;
	input[type="radio"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		display: inline-block;
		position: relative;
		padding: 0 ($md-radio-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		&:before, &:after {
			position: absolute;
			content: '';
			border-radius: 50%;
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 2px solid $md-radio-border-color;
		}
		&:after {
			top: 5px;
			left: 5px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			background: $md-radio-checked-color;
		}
	}
}

.checkbox {
	margin: 0;
	padding-top: 4px;
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		display: inline-block;
		position: relative;
		padding: 0;
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: top;
		&:before, &:after {
			position: absolute;
			content: '';
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 1px solid $md-radio-border-color;
		}
		&:after {
			top: -2px;
			left: 3px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			transform-origin: bottom;
			background: transparent;
			font-family: $fontawesome;
			content: "\f00c";
			color: $main;
		}
	}
}

/* Tooltips */
.tip-wrap {
	z-index: 10000;
	.tip {
		float: left;
		background: $bg;
		border: 1px solid $border;
		padding: 5px;
		max-width: 200px;
	}
	.tip-title {
		padding: 0;
		margin: 0;
		color: $main;
		font-size: 100%;
		font-weight: bold;
		margin-top: -15px;
		padding-top: 15px;
		padding-bottom: 5px;
	}
	.tip-text {
		font-size: 100%;
		margin: 0;
	}
}

.wf-columns {
	min-width: 100%;
	gap: 24px;
	&.boxes {
		.wf-column {
			box-shadow: 0 3px 6px rgb(0, 0, 0, 16%);
			border-radius: 36px;
			overflow: hidden;
			position: relative;
			margin-bottom: 24px;
			padding: 12px;
			padding-top: 24px;
			transition: all .3s cubic-bezier(.2, .5, .3, 1);
			background: $light;
			&:hover {
				z-index: 2;
				transform: translateY(-5px);
				box-shadow: 0px 8px 16px #00000099;
			}
		}
	}
	&.dark {
		@include bgfullflex($dark);
		padding-bottom: 24px;
	}
	&.link {
		@include bgfullflex($link);
		padding-bottom: 24px;
	}
	&.main {
		@include bgfullflex($main);
		padding-bottom: 24px;
	}
}

body:not(.home) .item-page {
	margin-bottom: 48px;
}

// Clean White Base Styling for sketch.accordion
body {
	div.sketchaccordion {
		.acc_clickwrap {
			h3.acc_trigger {
				text-transform: initial;
				font-weight: normal;
				border: 0;
				background: $link;
				color: $base;
				padding: 12px;
				padding-right: 48px;
				&:after {
					right: 12px;
					top: 12px;
				}
				&.trigger_active {
					color: $light;
					background: $main;
				}
			}
			.acc_sketchfold {
				padding: 12px;
				background: $link;
			}
		}
		p:empty {
			margin-bottom: 0;
		}
	}
}

.btn-success {
	color: #fff;
}

.btn-close + .multiselect {
	.multiselect__select {
		&:before {
			opacity: 0;
		}
	}
}

.swal2-container {
	z-index: 25000;
}

.form-check {
	margin-bottom: 12px;
}

.configWrap {
	background: $grey4;
	padding: 12px;
	@media #{$large}{
		padding: 24px;
	}
	@include clear();
	margin-bottom: 24px;
	&.purple{
		background: $purple2;
		h3 {
			color: $purple;
		}
		.card {
			&:hover {
				h3 {
					color: $purple;
				}
			}
			i {
				color: $purple;
			}
			h3 {
				color: $purple1;
			}
		}
	}
	&.blue {
		background: $blue3;
		h3 {
			color: $blue;
		}
		.card {
			&:hover {
				h3 {
					color: $blue;
				}
			}
			i {
				color: $blue;
			}
			h3 {
				color: $blue1;
			}
		}
	}
	&.red {
		background: $red2;
		h3 {
			color: $red;
		}
		.card {
			&:hover {
				h3 {
					color: $red;
				}
			}
			i {
				color: $red;
			}
			h3 {
				color: $red1;
			}
		}
	}
	&.orange{
		background: $orange2;
		h3 {
			color: $orange;
		}
		.card {
			&:hover {
				h3 {
					color: $orange;
				}
			}
			i {
				color: $orange;
			}
			h3 {
				color: $orange1;
			}
		}
	}

	&.green{
		background: $green2;
		h3 {
			color: $green;
		}
		.card {
			&:hover {
				h3 {
					color: $green;
				}
			}
			i {
				color: $green;
			}
			h3 {
				color: $green1;
			}
		}
	}

	.card {
		border: 0;
		transition: .3s all ease 0s;
		&:hover {
			box-shadow: 0px 3px 6px #00000029;
			h3 {
				color: $base;
			}
		}
		h3 {
			text-transform: initial;
			margin-bottom: 0;
			color: $grey;
		}
		i {
			color: $base;
		}
	}
}

span.passwordEye {
	position: absolute;
	right: 12px;
	top: 12px;
	z-index: 10;
	cursor: pointer;
	i {
		color: $grey1 !important;
	}
	&:hover {
		i {
			color: $blue !important;
		}
	}
}
.btn-check:checked + .btn-outline-success {
	color: $light;
}
