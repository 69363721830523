.task-list{
	.task-item{
		&.headline {
			.taskLineWrap:hover {
				background: transparent;
			}
			.taskLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				&.first{
					padding-top: 0 !important;
				}
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
	}
}
.taskList, .inDocument {
	.taskItem {
		padding-right: 12px;
		.taskInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			cursor: pointer;
			border-bottom: 1px solid $border;
			&:hover {
				background: $bgbox;
				.arrow {
					color: $blue;
				}
				.price {
					color: $black;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
				}
				&.title {
					width: 250px;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
			}
		}
	}
}

.fileList {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
	li i {
		cursor: pointer;
		&:hover {
			color: $blue;
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select {
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.taskItem {
		ul.taskInfoBar {
			li {
				padding-top: 6px;
				padding-left: 3px;
				padding-right: 3px;
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.final_price {
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

.appView.tasks, .appView.customers {
	.taskLineWrap {
		transition: .5s all ease 0s;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.d-flex {
			width: 100%;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding: 8px 6px 9px;
			min-width: 32px;
			display: inline-flex;
			align-self: center;
			.incNumber{
				@include font-size(11);
				position: relative;
				z-index: 1;
				width: 16px;
				display: block;
				height: 16px;
				text-align: center;
				transform: translateY(-7px);
				background: $purple2;
				line-height: 14px;
				border-radius: 100%;
				left: 0;
				top: 0;


			}
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-subtasks{
				padding-left: 12px;
				padding-right: 12px;
				width: 40px;
				text-align: center;
				justify-content: center;
			}
			&.l-attachments{
				padding-left: 12px;
				padding-right: 12px;
				width: 50px;
				text-align: center;
				justify-content: center;
			}
			&.l-comments{
				padding-left: 12px;
				padding-right: 12px;
				width: 60px;
				text-align: center;
				justify-content: center;
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
				min-width: 76px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 320px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				&.l-doctitle {
					width: 440px;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				width: 50px;
				text-align: center;
				justify-content: center;
			}
			&.l-state {
				width: 40px;
				text-align: center;
				justify-content: center;
			}
			&.l-unit {
				text-align: left;
				width: 100px;
				.badge {
					width: 100%;
				}
			}
			&.l-hours {
				width: 75px;
				align-items: center;
			}
			&.l-date {
				width: 100px;
				&.due {
					color: $red;
					font-weight: 500;
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				justify-content: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.last {
				padding-right: 16px;
			}
		}
	}
	h6.editLine {
		display: inline-block;
		padding-left: 12px;
		margin-bottom: 0;
	}
	.subTasks{
		.taskWrap{
			.taskLineWrap{
				.lineitem{
					&.l-title{
						text-overflow: initial;
						overflow: visible;
						white-space: normal;
					}
				}
			}
		}
	}
	.tasks > .card {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		border: 0;
		.taskLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		.card-header-inline{
			border: 0;
			padding-top: 9px;
			padding-bottom: 9px;
			h6.editLine{
				margin-top: 4px;
				display: block;
			}
		}
		.card {
			@include clear();
			border: 0;
			border-top: 0;
			margin-bottom: 12px;
			.card-body {
				border: 0;
				border-radius: 0 0 $br $br;
				border-top: 0;
				margin-bottom: 12px;
				width: 100%;
			}
		}
		.taskContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
	.taskComments {
		.list-group{
			border: 0;
			.list-group-item{
				border: 0;
				padding: 0;
				margin-bottom: 12px;
				border-bottom: 1px solid $border;
				&:last-child{
					border-bottom: 0;
				}
			}
		}
		.addNewCommentWrap{
			.ck.ck-content.ck-editor__editable {
				padding-left: 0.6em;
				border-color: rgba($border, 1);
				box-shadow: none;
				min-height: 140px;
			}
		}
		.inlineCommentWrap {
			@include clear();

			.infoLine{
				width: 100%;
				clear: both;
				display: block;
				small{
					display: inline-block;
					color: $grey1;
					&:hover{
						color: $base;
					}
					b{
						color: $base;
					}
					&.avatar {
						position: relative;
						top: -2px;
						margin-right: 6px;
					}
					&:not(.avatar) {
						float: right;
					}
					.sketchAvatar{
						position: relative;
						top: -2px;
						margin-right: 5px;
					}
				}
			}
		}
		.infoBox {
			min-width: 175px;
			text-align: right;
		}
	}
	.taskDetails {
		.taskDesc {
			+ .ck {
				.ck.ck-toolbar {
					border-color: transparent;
				}
			}
			&.on-blur {
				+ .ck {
					.ck.ck-content.ck-editor__editable {
						padding-left: 0;
						border-color: rgba($border, 0);
					}
					.ck.ck-toolbar__items {
						opacity: 0;
						height: 0;
					}
					.ck.ck-content {
						min-height: 156px;
						border-color: transparent;
					}
				}
			}
			&.on-focus {
				+ .ck {
					.ck.ck-toolbar {
						border-color: $border;
					}
					.ck.ck-content.ck-editor__editable {
						padding-left: 0.6em;
						border-color: rgba($border, 1);
						box-shadow: none;
						min-height: 140px;
					}
					.ck.ck-toolbar__items {
						opacity: 1;
						height: auto;
					}
				}
			}
		}
	}
}

button.checkCircleBtn.btn-outline-primary.btn.btn-sm {
	font-size: 8px;
	border-radius: 100%;
	width: 24px;
	height: 24px;
	padding: 0;
	text-align: center;
}
body #wrapper .hoverShowInput{
	background: transparent;
	border: 1px solid transparent;
	padding-top: 0;
	padding-bottom: 0;
	min-height: 0;
	&:hover{
		background: $bg;
		border: 1px solid transparent;
	}
}
.blurDesc {
	+ .ck {
		margin-left: 12px;
		margin-right: 12px;
		@media #{$large}{
			margin-left: 24px;
			margin-right: 24px;
		}
		.ck.ck-toolbar {
			border-color: transparent;
		}
	}
	&.on-blur {
		+ .ck {
			.ck.ck-content.ck-editor__editable {
				padding-left: 0;
				border-color: rgba($border, 0);
				max-height: 70px;
				overflow: hidden;
				min-height: 0;
			}
			.ck.ck-toolbar__items {
				opacity: 0;
				height: 0;
			}
			.ck.ck-content {
				min-height: 156px;
				border-color: transparent;
			}
			.ck.ck-editor__top.ck-reset_all {
				display: none;
			}
		}
	}
	&.on-focus {
		+ .ck {
			margin-top: 24px;
			.ck.ck-toolbar {
				border-color: $border;
			}
			.ck.ck-content.ck-editor__editable {
				padding-left: 0.6em;
				border-color: rgba($border, 1);
				box-shadow: none;
			}
			.ck.ck-toolbar__items {
				opacity: 1;
				height: auto;
			}
		}
	}
}
.modal-body{
	embed {
		width: 100%;
		min-height: 80vh;
	}
}
